import React from "react";
import { render } from "react-dom";
import configureStore from './config/configureStore'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from "history";
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import Root from './Root';

import './index.scss';
const history = createBrowserHistory();
const store = configureStore()
const wx = window.wx;

export {
    history,
    store,
    wx
}


render(
    <Router history={history}>
    <Root store={store} />
</Router>
, document.getElementById('root'));
