import {SHOW_PROMPT, 
  HIDE_PROMPT, 
  SHOW_TOPTICS, 
  SHOW_TOAST, 
  HIDE_TOAST, 
  HIDE_TOPTICS, 
  SHOW_LOADING, 
  HIDE_LOADING,
  SHOW_DIALOG,
  HIDE_DIALOG
} from './constants'

export const showPrompt = promptMsg => ({
  type: SHOW_PROMPT,
  promptMsg
})

export const hidePrompt = () => ({
  type: HIDE_PROMPT
})

export const showToptics = topticsMsg => ({
  type: SHOW_TOPTICS,
  topticsMsg
})

export const hideToptics = () => ({
  type: HIDE_TOPTICS
})
export const showToast = (toastMsg = "操作成功", icon) => ({
  type: SHOW_TOAST,
  toastMsg,
  icon
})
export const hideToast = () => ({
  type: HIDE_TOAST
})

export const showDialog = (title, description, affirm) => ({
  type: SHOW_DIALOG,
  title,
  description,
  affirm
})
export const hideDialog = () => ({
  type: HIDE_DIALOG
})

export const doShowDialog = (title, description, affirm) => (dispatch) => (
  dispatch(showDialog(title, description, affirm))
)



export const doHideDialog = () => (dispatch, getState) => (
  dispatch(hideDialog())
)

export const showLoading = (loadMsg = '加载中') => {
  return {
    type: SHOW_LOADING,
    loadMsg
  }
}

export const doShowLoading = (loadMsg) => (dispatch, getState) => (
  dispatch(showLoading(loadMsg))
)

export const hideLoading = () => ({
  type: HIDE_LOADING
})

export const doHideLoading = () => (dispatch, getState) => (
  dispatch(hideLoading())
)

export const doShowToast = (message, icon) => (dispatch, getState) => {
  return dispatch(showToast(message, icon))
}
export const doHideToast = () => (dispatch, getState) => {
  return dispatch(hideToast())
}

export const doShowToptics = (topticsMsg) => (dispatch, gerState) => {
  return dispatch(showToptics(topticsMsg))
}
export const doHideToptics = () => (dispatch, getState ) => {
  return dispatch(hideToptics());
}

export const actions = {
  showPrompt,
  hidePrompt,
  showToptics,
  hideToptics,
  showToast,
  hideToast,
  showDialog,
  hideDialog,
  showLoading,
  hideLoading
}