
const initialState = {
    logined: false,
    openid: '',
    telephone: '',
    staff: {}
}

// action types
export const types = {
    /** 登录 */
    LOGIN: "AUTH/LOGIN", 
    /** 注销 */   
    LOGOUT: "AUTH/LOGOUT"
};

export const actions = {
    logout: () => ({
        type: types.LOGOUT
    }),
    setLoginInfo: (openid, staff) => ({
        type: types.LOGIN,
        openid,
        staff: JSON.stringify(staff)
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case types.LOGIN:
            return {...state, logined: true}
        case types.LOGOUT:
            return {...state, logined: false, openid: '', staff: {}}
        default:
            return state;
    }
}

export default reducer;

// 获取用户登录信息
export const getLoginedUser = state => state.auth;
// 用户是否已登录
export const isLogined = state => state.auth.logined;
// 获取用户信息
export const getUserInfo = state => state.auth.staff;