import {actionTypes} from './constants';
import Storage from '../../utils/storage';
import {HOST} from '../../constants/index';
import {getUrlParam} from '../../utils/url';
import axios from 'axios';
import { httpGet } from '../../utils';

export const getWeixinAuthorizeUri = (state) => 
    state.weixinAuthorize;

const addWeixinInfo = state => ({
    type: actionTypes.ADD_WEIXIN_INFO,
    state
})

export const onAddWeixinInfo = (weixinInfo) => (dispatch, getState) => {
    return dispatch(addWeixinInfo(weixinInfo))
}

// 未授权
export const NOT_AUTHORIZE = 'not authorize';
// 未登录
export const NOT_LOGIN = 'not login';


let isGettingOpenid = false;

/**
 * 微信是否已授权
 */
export const isWeixinAuthorize = (() => !!localStorage.getItem('openid'))();


/**
 * 判断是否是微信浏览器
 */
export const isWeixinBrower = (() => {
    var ua = window.navigator.userAgent.toLowerCase();
    return String(ua.match(/MicroMessenger/i)) === 'micromessenger';
})()

/**
 * TODO: 请求微信 获取openid
 * code 换取 openid
 * 弃用
 */
const requestOpenid = () => {
    const code = getUrlParam('code');
    // 将微信授权时的state值设置为 用户id,如果不存在则重新登录
    const isLogin = getUrlParam('state');

    return new Promise( (resolve, reject) => {
        if (!isGettingOpenid) {
            // TODO: code换取openid
            isGettingOpenid = true;
            axios.get(HOST.wx_webAuth + code)
                .then(response => {
                    console.log('===========code换取的微信用户信息=========================');
                    console.log(response);
                    console.log('====================================');
                    let data = response.data.data;
                    if (data && data.openid){
                        // TODO: 用户成功授权
                        Storage.saveObject('wx_info', data);
                        Storage.save('openid', data.openid);
                        Storage.remove('currCodeTime');
                        resolve({
                            isLogin
                        });
                    } else {
                        isGettingOpenid = false;
                        reject();
                    }


                    // if (response.data.code == '40163') {
                    // }

                })
        }
    })

};

/**
 * 微信授权
 * 弃用
 */
export const doAuthorizeHandle = function () {

    return new Promise( (resolve, reject) => {
        /**
         * TODO: openid不存在
         */
        if (!Storage.read('openid')) {
            // 没有openid
            console.log('未找到openid');

            const code = getUrlParam('code');
            // 当前code时间
            const currCodeTime = Storage.read('currCodeTime');

            // 1. 是否正在授权页面
            if (code) {
                // TODO: 准备授权...，url参数带有code和state
                // 1.1 正在授权 -> 是否过期 -> 获取openid
                if (currCodeTime) {
                    // 授权code是否过期
                    const now = new Date().getTime();
                    const diff = now - currCodeTime;
                    const diffMinutes = Math.floor(diff % (60 * 60 * 24 * 1000) / (60 * 1000));
                    if (diffMinutes >= 5) {
                        console.log('过期，重新请求')
                        //    code超时，重新获取code
                        // redirectToAuthorizePage();
                        reject({
                            message: NOT_AUTHORIZE
                        })
                    }
                }

                requestOpenid()
                    .then(res => {
                        if (!res.isLogin) {
                            reject({
                                message: NOT_LOGIN
                            })
                        }
                    })
                    .catch(error => {
                        reject({
                            message: NOT_AUTHORIZE
                        });
                    })
            } else {
                // 2. 没有再授权页面 -> 跳转
                // redirectToAuthorizePage();
                reject({
                    message: NOT_AUTHORIZE
                })
            }
            return false;
        } else if (!JSON.parse(sessionStorage.getItem('staff')).id) {
            // 存在openid，但没有登录
            reject({
                message: NOT_LOGIN
            })
        } else {
            resolve()
        }
    });

};

/**
 * 校验openid是否绑定手机号码
 * @return object {success, staff}
 *   success: ture | false
 *   staff: {id:xx ....,telephone...}
 */
export const getInfoByOpenid = (openid = localStorage.getItem('openid')) => {
    return new Promise( (resolve, reject) => {
        httpGet(HOST.staff_login, {
            openid
        })
        .then(res => {
            console.log('校验openid是否绑定手机号码')
            if(res.data.status + '' === '0') {
                let data = res.data.data;
                if (data.telephone && data.openId) {
                    if (!localStorage.getItem('openid')) {
                        localStorage.setItem('openid', data.openId);
                    }
                    sessionStorage.setItem('staff', JSON.stringify(data));
                    resolve({
                        errCode: '0',
                        openid: data.openid,
                        staff: JSON.stringify(data)
                    })
                }
            } else if (res.data.status + '' === '-1') {
                // 绑定
                reject({
                    errCode: '-1',
                    status: -1,
                    msg: res.data.msg
                }); 
            } else {
                reject({
                    errCode: '-1',
                }); 
            }
        })
        .catch(err => {
            reject({
                errCode: '-1',
            });
        })
    })
}