export const user = require('./user.png');
export const feedback = require('./feedback.png');
export const print = require('./print.png');
export const scan = require('./scan.png');
export const setter = require('./setter.png');
export const table_service = require('./table_service.png');

export const record = require('./record.png');
export const statis = require('./statis.png');

export const lingW = require('./ling_w.png');
export const lingB = require('./ling_b.png');

export const chartW = require('./chart-w.png');
export const chartB = require('./chart-b.png');
export const img80 = require('./img_80.jpg');
export const files = require('./files.png');

export const person = require('./person.png');
export const meter = require('./meter.png');
export const book = require('./book.png');
export const business = require('./business.png');
export const admin = require('./admin.png');
export const lock = require('./lock.png');
export const reminderTemplateImg = require('./reminder-template.png');
export const checkboxLine = require('./checkboxline.png');
export const successfulBlue = require('./successful-blue.png');
export const company = require('./company.png');
export const area = require('./area.png');
export const zone = require('./zone.png');
export const readCycle = require('./readCycle.png');
export const reduction = require('./reduction.png');
export const weixin = require('./weixin.png');
export const invoiceIcon = require('./invoice.png');
export const account = require('./account.png');