import {actionTypes, AUTHORIZE_BASE_URL, WEIXIN_LOGIN_URL, APPID, OPEN_APPID, LOGIN_SCOPE, REDIRECT_URI, SCOPE} from './constants'

/**
 * 用户同意授权，获取code 地址
 appid 必须 公众号的唯一标识
 redirect_uri	是	授权后重定向的回调链接地址， 请使用 urlEncode 对链接进行处理
 response_type	是	返回类型，请填写code
 scope	是	应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
 state	否	重定向后会带上state参数，开发者可以填写a-zA-Z0-9的参数值，最多128字节
 #wechat_redirect	是	无论直接打开还是做页面302重定向时候，必须带此参数
 */
export const authorizeLink = AUTHORIZE_BASE_URL.replace('APPID', APPID)
    .replace('REDIRECT_URI', encodeURIComponent(REDIRECT_URI))
    .replace('SCOPE', SCOPE)
    .replace('STATE', Math.floor(Math.random() * 10))

export const weixinLoginLink = () => WEIXIN_LOGIN_URL.replace('APPID', OPEN_APPID)
.replace('REDIRECT_URI', encodeURIComponent(REDIRECT_URI))
.replace('SCOPE', LOGIN_SCOPE)
.replace('STATE', 'pc')

export function weixinAuthorize(state = {authorizeLink}, action) {
    return state;
}

const weixinInfoInitialState = {
    "openid": "",
    "nickname": "",
    "sex":"",
    "province":"",
    "city":"",
    "country":"",
    "headimgurl": "",
    "privilege":[],
    "unionid": ""
}

export function weixinInfo(state = weixinInfoInitialState, action) {
    const actionState = action.state;
    switch(action.type) {
        case actionTypes.ADD_WEIXIN_INFO:
        return {
            ...actionState
        }
        default:
            return state;
    }
}