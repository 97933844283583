import axios from 'axios'
import { showLoading, hideLoading } from '../common/prompt';
import { storage, addLabelToArray } from '../utils'
import { store } from '../';
import { HOST, often, path } from '../constants';
// import { path } from '../constants';

// let headersCommon = axios.defaults.headers.common;
// Object.assign(headersCommon, {
//     openid: sessionStorage.getItem('openid'),
//     userId: JSON.parse(sessionStorage.getItem('staff')).id
// })

// axios.defaults.headers.common['openid'] = sessionStorage.getItem('openid');


// let currCodeTime = new Date().getTime();


/**
 * TODO: 重定向到登录页面
 */
export const redirectToLoginPage = () => {
    window.location.href = path.login + '?timestamp='+((new Date()).getTime()+Math.random());
};

/**
 * TODO: 重定向到用户访问地址
 */
export const redirectToUserRequestUri = () => {
    window.location.href = storage.read('REDIRECT_URI') + '?timestamp='+((new Date()).getTime()+Math.random());
};

axios.defaults.timeout = 60000;

let requestConfig = {};

/** 设置拦截器 */
axios.interceptors.request.use(function (config) {
    store.dispatch(showLoading());
    // Do something before request is sent
    let openid = localStorage.getItem('openid');
    let staff = JSON.parse(sessionStorage.getItem('staff'));
    if (openid && staff) {
        config.headers.openid = openid;
        config.headers.userid = staff.id;
        requestConfig = config;
    }
    if (process.env.NODE_ENV === 'development') {
        console.log(`================ 拦截器请求: ${config.url} ====================`);
        console.log(config);
        console.log('====================================');
    }
    return config;
  }, function (error) {

    process.env.NODE_ENV === 'development' && console.log('请求失败');

    store.dispatch(hideLoading());
    // Do something with request error
    return Promise.reject(error);
  });
 
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    store.dispatch(hideLoading())
    if (process.env.NODE_ENV === 'development') {
        console.log(`=============== 请求响应成功: ${response.request.responseURL}=====================`);
        console.log(response)
        console.log('====================================');
    }
    // Do something with response data
    return response;
  }, function (error) {
      store.dispatch(hideLoading());
      if (process.env.NODE_ENV === 'development') {
          console.log('=============== 响应失败 =====================');
          console.log( error.response );
          console.log( error.status )
          console.log( error.message )
          console.log('====================================');
      }

    //   if (error.response.status) {
    //         error.status = error.response.data.status
    //         error.message = error.response.data.message;  
    //     switch (error.status) {
    //         case 400:
    //           error.message = '请求错误'
    //           break
      
    //         case 401:
    //           error.message = '未授权，请登录'
    //           break
      
    //         case 403:
    //         doShowToast('拒绝访问', 'error')
    //           error.message = '拒绝访问'
    //           break
      
    //         case 404:
    //           error.message = `请求地址出错: ${error.response.often.url}`
    //           break
      
    //         case 408:
    //           error.message = '请求超时'
    //           break
      
    //         case 500:
    //           error.message = '服务器内部错误'
    //           break
      
    //         case 501:
    //           error.message = '服务未实现'
    //           break
      
    //         case 502:
    //           error.message = '网关错误'
    //           break
      
    //         case 503:
    //           error.message = '服务不可用'
    //           break
      
    //         case 504:
    //           error.message = '网关超时'
    //           break
      
    //         case 505:
    //           error.message = 'HTTP版本不受支持'
    //           break
      
    //         default:
    //       }
    //   }
   
    return Promise.reject(error);
  });




/**
 * get请求
 * @param {string} url 
 */

export const httpGet = (url, params) => {
    return axios({
        url: url,
        method: 'get',
        params
    })
}

/**
 * post请求
 * @param {string} url 
 * @param {object} params 
 */
export const httpPost = (url, params) => {
    let headers = Object.assign({}, requestConfig.headers, {
        'Content-Type': 'application/x-www-form-urlencoded'
    })
    return axios({
        url: url,
        method: 'post',
        params: params,
        headers,
        transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
          }]
    })
}

/**
 * post form请求 序列化 
 * 传递的param需要 JSON.stringify(data)
 * @param {string} url 
 * @param {object} params 
 */
export const httpForm = (url, params) => {
    let headers = Object.assign({}, requestConfig.headers, {
        'Content-Type': 'application/x-www-form-urlencoded'
    })
    return axios({
        url: url,
        method: 'post',
        data: params,
        headers,
        transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }]
    })
}
/**
 * http delete 请求
 * @param {string} url 
 * @param {object} params 
 */
export const httpDelete = (url, params) => {
    let headers = Object.assign({}, requestConfig.headers, {
        'Content-Type': 'application/json;charset=UTF-8'
    })
    return axios({
        url: url,
        method: 'delete',
        params: params,
        headers
    })
}

/**
 * 文件上传
 * @param {string} url 
 * @param {object} params 
 */
export const httpPostFile = (url, params) => {
    let headers = Object.assign({}, requestConfig.headers, {
        'Content-Type': 'multipart/form-data'
    })
    return axios({
        url: url,
        method: 'post',
        data: params,
        headers
    })
}

/**
 * 请求远端全部配置
 */
// export const requestRemoteCfg = (cb) =>{

//     return new Promise( resovle => {
//         if (
//             storage.readObject('company') &&
//             storage.readObject('district') &&
//             storage.readObject('business') &&
//             storage.readObject('zone') &&
//             storage.readObject('readCycle') &&
//             storage.readObject('readMeterStaff') 
//     ) {
//         // 如果有配置存在缓存
//         resovle(true)
//     } else {
//         // 配置不存在缓存，从服务器拉取
//         axios.all(
//             [
//                 httpGet(HOST.company_findAll),
//                 httpGet(HOST.district_findAll),
//                 httpGet(HOST.business_findAll),
//                 httpGet(HOST.zone_findAll),
//                 httpGet(HOST.readCycle_findAll),
//                 httpGet(HOST.staff_getReadMeterStaff)
//             ]
//         )
//         .then(response => {
//             Storage.saveObject('company', response[0].data.data);
//             Storage.saveObject('district', response[1].data.data);
//             Storage.saveObject('business', response[2].data.data);
//             Storage.saveObject('zone', response[3].data.data);
//             Storage.saveObject('readCycle', response[4].data.data);
//             Storage.saveObject('readMeterStaff', response[5].data.data);
//             resovle(true)
//         })
//     }

//     })
// }


/**
 * 载入数据项到缓存
 * @param {array} keys 配置关键字 如: ['books']
 * books company district business zone readCycle readMeterStaff
 */
export const loadConfig = (keys) => {
    // 请求超时时间，如果请求错误，将递减
    let timeout = 30;
    let shouldRequest = false;
    // 需要请求的地址数组 
    let requestObjects = [];

    // 参数中的缓存数据
    let storeDatas = [];

    // 判断是否需要远程请求
    keys.forEach( (key, index) => {
        let storeData = storage.readObject(key);
        let obj = {key};
        if (!storeData) {
            if (key === often.company) {
                obj.url = HOST.company_findAll;
            } else if (key === often.district) {
                obj.url = HOST.district_findAll;
            } else if (key === often.business) {
                obj.url = HOST.business_findAll;
            } else if (key === often.zone) {
                obj.url = HOST.zone_findAll;
            } else if (key === often.readCycle) {
                obj.url = HOST.readCycle_findAll;
            } else if (key === often.readMeterStaff) {
                obj.url = HOST.staff_getReadMeterStaff;
            } else if (key === often.books) {
                obj.url = HOST.books_findAll;
            } else if (key === often.admin) {
                obj.url = HOST.staff_findAdmin;
            } else if (key === often.role) {
                obj.url = HOST.role_finAll;
            } else if (key === often.authorize) {
                obj.url = HOST.authorize_findAll;
            }
            requestObjects.push(obj);
        } else {
            storeDatas.push(storeData) 
        }
    });
    // 如果有的有，有的没有的情况下怎么办？

    // 请求个数
    let len = requestObjects.length;
    if (len === 0) {
        // 无需请求,返回所有数据
        return new Promise(resolve => {
            resolve(storeDatas);
        })
    } else {
        // 请求
        if (len === 1) {

            httpGet(requestObjects[0].url)
            .then(res => {
                let result = res.data.data;
                if (result) {
                    result = addLabelToArray(result);
                    storage.saveObject(requestObjects[0].key, result);
                    shouldRequest = true; 
                }
                
            })
            .catch(error => {
                setTimeout(() => {
                    timeout --;
                    loadConfig(keys);
                }, 1000)
            })
        } else {
            let requestAll = [];
            for (let i = 0; i < len; i ++) {
                let requestUrl = requestObjects[i].url
                requestAll.push(httpGet(requestUrl))
            }

            axios.all(requestAll)
            .then(response => {

                let results = [];
                for(let i = 0; i < response.length; i ++) {
                    let data = response[i].data.data;
                    if (data) {
                        data = addLabelToArray(data); 
                        storage.saveObject(requestObjects[i].key, data);
                        results.push(data);
                    }
                }
                shouldRequest = true; 
            })
            .catch(error => {
                setTimeout(() => {
                    timeout --;
                    loadConfig(keys);
                }, 1000)
            })
        }
        return new Promise( resolve => {
            let loop = setInterval(() => {
                if (shouldRequest || timeout <= 0) {
                    clearInterval(loop);
                    let datas = (function() {
                        storeDatas = [];
                        keys.forEach( (key, index) => {
                            storeDatas[index] = storage.readObject(key)
                        })
                        return storeDatas;
                    }())
                    resolve( datas )
                }
                timeout --;
            }, 1000)
        })
    }
}
const downFile = (blob, fileName) =>{
 // 非IE下载
 if ('download' in document.createElement('a')) {
   let link = document.createElement('a');
   link.href = window.URL.createObjectURL(blob); // 创建下载的链接
   link.download = fileName; // 下载后文件名
   link.style.display = 'none';
   document.body.appendChild(link);
   link.click(); // 点击下载
   window.URL.revokeObjectURL(link.href); // 释放掉blob对象
   document.body.removeChild(link); // 下载完成移除元素
 } else {
   // IE10+下载
   window.navigator.msSaveBlob(blob, fileName);
 }
};
export const downloadFile = (url, data) => {
  let requestData = Object.assign({}, data, {
    accessToken: sessionStorage.getItem('accessToken')
  });

  // 响应类型：arraybuffer, blob
  axios.post(url, requestData, {responseType: 'blob'}).then(resp => {
    let headers = resp.headers;
    let contentType = headers['content-type'];

    console.log('响应头信息', headers);
    if (!resp.data) {
      console.error('响应异常：', resp);
      return false;
    } else {
      console.error('下载文件：', resp);
      const blob = new Blob([resp.data], {type: contentType});

      const contentDisposition = resp.headers['content-disposition'];
      console.log('headers');
      console.log(headers);
      let fileName = 'unknown';
      if (contentDisposition) {
        fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
      }
      console.log('文件名称：', fileName);
      downFile(blob, fileName);
    }
  }).catch(function (error) {
      console.log(error);
  });
}


export function downloadFileRequest(periods, rt) {
  let url = HOST.download_report + '?periods=' + periods + '&rt='+ rt;
    window.location.href = url;
  }
// /**
//  * 
//  * @param {array} iterable [httpGet('...'), httpGet('...')]
//  * @param {*} callback 
//  */
// export const httpAll = (iterable, callback) =>  new Promise(  (resolve, reject) => (
//     axios.all(...iterable)
//     .then(axios.spread(function(acct, params) {
//         resolve(acct, params)
//     }))
//     .catch(err => reject(err))
// ))