import { APPID } from '../common/weixin/constants';
import * as img from '../resources/images'; 
import HOST from './requestUrl';
export { HOST }

/**
 * ========= 页面地址 =========
 */
export const path = {
    /** 首页 */
    home: '/',
    authorize: '/authorize',
    /** 登录 */
    login: '/login',
    /** 设置密码 */
    setPassword: '/setPassword',
    setPasswordSuccess: '/setPasswordSuccess',
    /** 仪表盘 */
    dashboard: '/dashboard',

    /** 用户 */
    paymentDetail: '/paymentDetail',
    paymentConfirm: '/paymentConfirm',
    paymentRecordList: '/paymentRecordList',
    userList: '/userList',
    userDetail: '/userDetail',
    addUser: '/addUser',
    userBundleImport: '/userBundleImport',
    addUserLogger: '/addUserLogger',
    editUser: '/editUser',

    /** 报表 */
    report: '/report',
    /** 数据报表 */
    datatable: '/datatable',
    /** 表务 */
    meterList: '/meterList',
    meterManageList: '/menus/meter',
    editMeter: '/editMeter',
    addMeter: '/addMeter',
    changeRecord: '/changeRecord',
    meterReadList: '/meterReadList',
    meterReadRecord: '/meterReadRecord',
    selectReadPeriod: '/selectReadPeriod',
    bundleImportReadMeter: '/bundleImportReadMeter',

    /** 抄表人员 */
    readMeterStaffList: '/readMeterStaffList',
    addReadMeter: '/addReadMeter',
    editReadMeter: '/editReadMeter',

    /** 统测号 */
    booksList: '/booksList',
    selectBooks: '/selectBooks',
    editBooks: '/editBooks',
    addBooks: '/addBooks',

    /** 扫码收款 */
    scan: '/scan',

    /** 发送通知 */
    sendNotice: '/sendNotice',
    /** 打印 */
    printMenusList: '/menus/print',
    // 打印记录
    printRecord: '/printRecord',
    // 打印机修改
    editPrint: '/editPrint',
    // 发票管理
    // invoiceManageList: '/invoiceManageList',
    invoiceManageList: '/menus/invoice',
    // 打印机管理
    // printManageList: '/printManageList',
    printerManage: '/printerManage',
    // 催缴单管理
    // reminderManageList: '/reminderManageList',
    reminderManageList: '/menus/reminder',
    // 催缴单模板管理
    reminderTemplate: '/reminderTemplate',
    /** 管理与设置 */
    settingManageList: '/menus/manage',
    sMSManageList: '/sMSManageList',
    weixinPushManageList: '/weixinPushManageList',
    // noticeManageList: '/noticeManageList',
    noticeManageList: '/menus/notice',
    businessManageList: '/businessManageList',

    // // 水司
    // companyManageList: '/companyManageList',
    // // 区域
    // districtManageList: '/districtManageList',
    // // 范围
    // zoneManageList: '/zoneManageList',
    // // 抄表周期
    // readCycleManageList: '/readCycleManageList',

    // 统一管理列表 水司、区域、范围、周期
    manageList: '/manageList',
    // 统一编辑页面 水司、区域、范围、周期
    editPage: '/editPage',
    
    roleManageList: '/roleManageList',
    adminManageList: '/adminManageList',
    businessEditor: '/businessEditor',
    setReduction: '/setReduction',
    addAdmin: '/addAdmin',
    adminEditor: '/adminEditor',
    addRole: '/addRole',
    roleEditor: '/roleEditor',
    weixinSetting: '/weixinSetting',
    accountSettings: '/accountSettings',

    // 结果反馈
    resultFeedback: '/ResultFeedback',

    /**
     * 管理菜单列表
     */
    menus: '/menus',
    // 订单详情
    orderDetail: '/orderDetail',
    /** 错误页面 */
    error404: '/404'
}

export const manageListPage = {
    manage: {
        title: '管理与设置',
        items: [
            {icon: img.business, label: '营业厅管理', to: path.manageList + '/business'},
            
            {icon: img.company, label: '水司管理', to: path.manageList + '/company'},
            {icon: img.area, label: '区域管理', to: path.manageList + '/district'},
            {icon: img.zone, label: '范围管理', to: path.manageList + '/zone'},
            {icon: img.readCycle, label: '抄表周期管理', to: path.manageList + '/readCycle'},

            // {icon: img.lingB, label: '通知管理', to: path.noticeManageList},
            {icon: img.admin, label: '管理员设置', to: path.adminManageList},
            {icon: img.reduction, label: '减免吨数设置', to: path.setReduction},
            {icon: img.lock, label: '权限管理', to: path.roleManageList},
            {icon: img.weixin, label: '微信接口设置', to: path.weixinSetting},
            {icon: img.account, label: '账号设置', to: path.accountSettings},
        ]
    },
    meter: {
        title: '表务管理',
        items: [
            {icon: img.meter, label: '水表', to: path.meterList},
            {icon: img.lingB, label: '抄表员', to: path.readMeterStaffList}, 
            {icon: img.book, label: '统测号', to: path.booksList}
        ]
    },
    notice: {
        title: '通知管理',
        items: [
            {icon: img.meter, label: '短信通知', to: '#'},
            {icon: img.lingB, label: '微信通知', to: '#'},
        ]
    },
    sms: {
        title: '短信通知',
        items: [
            // {icon: img.meter, label: '发票', to: path.meterList},
            {icon: img.lingB, label: '催缴单管理', to: path.readMeterStaffList},
            {icon: img.book, label: '打印机管理', to: path.booksList},
        ]
    },
    weixin: {
        title: '微信推送管理',
        items: [
            {icon: img.meter, label: '微信模板消息', to: path.meterList},
            {icon: img.lingB, label: '微信客服消息', to: path.readMeterStaffList},
        ]
    },
    print: {
        title: '打印',
        items: [
            // {icon: img.meter, label: '发票管理', to: path.invoiceManageList},
            {icon: img.lingB, label: '催缴单管理', to: path.reminderManageList},
            {icon: img.book, label: '打印机管理', to: path.printerManage},
        ]
    },
    // ticketType  1 催缴单 2 发票
    invoice: {
        title: '发票管理',
        items: [
            {icon: img.meter, label: '发票打印记录', to: path.printRecord + '/2'},
            {icon: img.lingB, label: '发票模板设置', to: path.readMeterStaffList},
        ]
    },
    reminder: {
        title: '催缴单管理',
        items: [
            {icon: img.meter, label: '催缴单打印记录', to: path.printRecord + '/1'},
            {icon: img.lingB, label: '催缴单模板设置', to: path.reminderTemplate},
        ]
    }
}

/**
 * 常用的配置
 */
export const often = {
    company: 'company',
    district: 'district',
    business: 'business',
    zone: 'zone',
    readCycle: 'readCycle',
    readMeterStaff: 'readMeterStaff',
    books: 'books',
    admin: 'admin',
    role: 'role',
    authorize: 'authorize'
}

export const weixin = {
    appid: APPID
}

/**
 * 缴费方式:
 * scan 1：微信支付或扫码支付 
 * miniApp 2：小程序 
 * behalf 3：管理员代缴 
 * cash 4：现金  
 * qrcode 5：公司二维码
 */
export const paymentMethod = {
    scan: 1,
    miniApp: 2,
    behalf: 3,
    cash: 4,
    qrcode: 5
}

/**
 * 缴费来源
 * scan 管理员后台扫码支付
 * cash 管理员后台现金缴费
 */
export const paymentSource = {
    scan: 1,
    cash: 2
}

/**
 * 字典集
 */
export const DictionaryLibrary = {
    // 付款方式
    paymentMethod: []
};

DictionaryLibrary.paymentMethod[0] = '现金';