const storage = window.sessionStorage;
const localStorage = window.localStorage;
const authorKey = 'authority';
const meterReadListKey = 'meterReadList';
const openidKey = 'openid';
const staffKey = 'staff';

const Storage = {
    read: function (key) {
        return storage.getItem(key);
    },
    save: function (key, value) {
        return storage.setItem(key, value);
    },
    saveObject: function (key, value) {
        return storage.setItem(key, JSON.stringify(value));
    },
    readObject: function (key) {
        return JSON.parse(storage.getItem(key));
    },
    remove: function (key) {
        return storage.removeItem(key);
    },
    // 获取抄表list
    getMeterReadList: function () {
      return this.readObject(meterReadListKey);
    },
    // 保存抄表list
    putMeterReadList: function (value) {
        this.saveObject(meterReadListKey, value);
    },
    /**
     * 保存 openid
     */
    // saveOpenid: function(value) {
    //     return localStorage.setItem(openidKey, value);
    // },
    saveOpenid:  (value, cb) => {
        localStorage.setItem(openidKey, value);
        if (typeof cb === 'function') {
            setTimeout(() => {
                cb(value);
            },500)
        }
    },
    removeOpenid: function() {
        localStorage.removeItem(openidKey);
    },
    /**
     * 获取 openid
     */
    getOpenid: ( () => localStorage.getItem(openidKey) )(),
    /**
     * 保存员工信息
     */
    saveStaff: function(value) {
        return localStorage.setItem(staffKey, JSON.stringify(value));
    },
    /**
     * 获取员工信息
     * @return object
     */
    // getStaff: (() => {
    //     let staff = sessionStorage.getItem('staff');
    //     return JSON.parse(staff);
    // })(),
    removeStaff: () => {
        localStorage.removeItem(staffKey);
    },
    // 获取验证
    getAuthor: function () {
        return this.read(authorKey);
    },
    // 保存验证
    putAuthor: function (value) {
       this.save(authorKey, value);
    },

    // 获取水司
    getCompany: function() {
        return this.readObject('company');
    },
    // 获取营业厅
    getBusiness: function() {
        return this.readObject('business');
    },
    // 获取区域
    getDistrict: function() {
        return this.readObject('district');
    },
    // 获取范围
    getZone: function() {
        return this.readObject('zone');
    },
    // 获取抄表周期
    getReadCycle: function() {
        return this.readObject('readCycle');
    },
    // 获取员工信息
    getReadMeterStaff: function() {
        return this.readObject('readMeterStaff');
    },
    // /**
    //  * 新增标识
    //  * flag 新创建标识
    //  */
    // addFlag: function(flag) {
    //     return this.save('ADD_' + flag, true)
    // },
    // /**
    //  * 获取标识
    //  */
    // getFlag: function(flag) {
    //     return this.read('ADD_' + flag  )
    // },
    // /**
    //  * 删除标识
    //  */
    // removeFlag: function(flag) {
    //     return this.remove('ADD_' + flag )
    // }

};
export default Storage