import React, {Component} from 'react';
import PropTypes from 'prop-types'
import { bindActionCreators } from "redux";
import { actions as PromptType} from '../../common/prompt/actions';
import {actions as authActions, isLogined} from '../../reducers/auth';
import MivContainer from "../../components/miv-container/miv-container";
import {
    Button,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Input} from "react-weui";

import { httpGet } from '../../utils/';
import { connect } from 'react-redux'
import { path, HOST } from '../../constants';
import {isWeixinBrower, weixinLoginLink} from '../../common/weixin';

import './login.scss';

class Login extends Component {
    static propTypes = {
        telephone: PropTypes.string,
        vcode: PropTypes.string,
      }

    constructor(props) {
        super(props);
        this.state = {
            sendCodeMessage: '点击获取验证码',
            enableSendCode: true
        };

        this.sendCodeHandle = this.sendCodeHandle.bind(this);
        this.verificatAction = this.verificatAction.bind(this);
    }
    componentWillMount() {
       
    }
    componentDidMount() {
    }
    /**
     * 发送验证码
     */
    sendCodeHandle() {
        let {sendCodeMessage, enableSendCode, telephone } = this.state;

        if (!(/^1[34578]\d{9}$/.test(telephone))) {
            this.props.showToptics('请正确填写手机号码');
            return false;
        }
        if (enableSendCode) {
            let initMessage = sendCodeMessage.slice('');
            let countdown = 60;

            httpGet(HOST.getLoginCode, {
                phone: telephone,
                product: '厦门安兜自来水登录验证'
            })
            .then(res => {
                this.setState({
                    sendCodeMessage: '(' + countdown-- + 's)',
                        enableSendCode: false 
                })
                let timer = setInterval(() => {
                    countdown -- ;
                    let stateObj = {
                        sendCodeMessage: '(' + countdown + 's)',
                        enableSendCode: false
                    }
                    if (countdown <= 0) {
                        clearInterval(timer);
                        stateObj = {
                            sendCodeMessage : initMessage,
                            enableSendCode: true
                        }
                    }
                    this.setState(stateObj)
                }, 1000);
            })
            .catch(err => {
                this.props.showToptics(err.msg);
            })
           
        }
    }

    toAuthPage() {
        this.props.history.replace({
            pathname: path.home
        }) 
    }
    toSetPassword() {
        this.props.history.replace({
            pathname: path.setPassword,
            state: this.state.telephone
        })
    }
    toLoginSuccess() {
        this.props.history.replace({
            pathname: path.dashboard
        })
    }
    weixinLogin() {
        window.location = weixinLoginLink() + '?timestamp='+((new Date()).getTime()+Math.random());
    }

    /**
     * 验证
     */
    verificatAction(event) {
        event.preventDefault();
        const {telephone, vcode} = this.state;

        if(!(/^1[34578]\d{9}$/.test(telephone))){
            this.props.showToptics("手机号码有误，请重填");
            return false;
        }
        if (!vcode) {
            this.props.showToptics('验证码为空，请重新填写');
            return false;
        }

        httpGet(HOST.staff_checkPhone, {
            phone: telephone,
            code:vcode
        })
        .then(res => {
            let openid = res.data.openId;
            let code = res.data.code + '';

            // 登入
            if (code === '0') {
                localStorage.setItem('openid', openid);
                sessionStorage.setItem('staff', JSON.stringify(res.data))
                this.props.setLoginInfo(openid, res.data);
                this.toLoginSuccess();
            }
            else if (code === '18006') {
                this.props.showToptics(res.data.msg);
                return false;
            }
           else {
                // 还未设置密码 & 微信
                if ( isWeixinBrower ) {
                    // 微信用户 除了手机不允许登入
                    if (code === '18002') {
                        this.props.showToptics(res.data.msg);                        
                    } else {
                        sessionStorage.setItem('staff', JSON.stringify(res.data))
                        this.toSetPassword();
                        return false;
                    }
                }
                // 还未设置密码 & 非微信
                else {
                    this.props.showToptics('请先使用微信授权登入');
                }
           }

        })
        .catch(error => {
            this.props.showToptics('网络错误！请稍后重试!');
        })
    }

    handleChange = (event) => {
        const target = event.target;
        this.setState({[target.name]: target.value})
    }

    render() {
        return (
                <MivContainer className="author_container">
                <h4 className="author_title">首次使用请先验证手机号</h4>
                <Form className="input_wrap margin-bottom">
                    <FormCell vcode>

                        <CellBody>
                            <Input type="tel" name="telephone" placeholder="请输入手机号码" maxLength="11" onChange={this.handleChange} />
                        </CellBody>
                        <CellFooter>
                            <Button type="vcode" 
                                className="no-border sm-font color-level11"
                                onClick={this.sendCodeHandle}>{this.state.sendCodeMessage}</Button>
                        </CellFooter>
                    </FormCell>
                    <FormCell>
                        <CellBody>
                            <Input type="number" name="vcode" placeholder="请输入验证码" onChange={this.handleChange}/>
                        </CellBody>
                    </FormCell>
                    <div className="padding margin-top">
                        <Button className="bgcolor-level11" onClick={ this.verificatAction }>验证</Button>
                    </div>
                    <div className="text-center color-level3 sm-font">
                        如验证失败请联系管理员
                    </div>
                </Form>
                {/* <div className="other_login">
                    <span>其他登陆</span>
                    <Button onClick={this.weixinLogin}>微信登录</Button>
                </div> */}
            </MivContainer>
        )
    }
}

const mapStateToProps = state => ({
    isLogined: isLogined(state)
})

const mapDispatchToProps = dispatch => {
    return {
      ...bindActionCreators(authActions, dispatch),
      ...bindActionCreators(PromptType, dispatch)
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Login);