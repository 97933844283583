
import {
    weixinAuthorize,
    weixinLoginLink,
    weixinInfo
} from './reducer'

import {
 getWeixinAuthorizeUri,
 onAddWeixinInfo,
    // doAuthorizeHandle,
    NOT_AUTHORIZE,
    NOT_LOGIN,
    isWeixinAuthorize,
    isWeixinBrower,
    getInfoByOpenid
} from './actions'

import {
    OPEN_APPID,
    REDIRECT_URI
} from './constants';


export {
    isWeixinAuthorize,
    weixinInfo,
    weixinAuthorize,
    weixinLoginLink,
    OPEN_APPID,
    REDIRECT_URI,
    onAddWeixinInfo,
    isWeixinBrower,
    getWeixinAuthorizeUri,
    NOT_AUTHORIZE,
    NOT_LOGIN,
    getInfoByOpenid
}