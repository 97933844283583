import React, {Component} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { actions as PromptType} from '../common/prompt/actions';
import { actions as actionSheetAction, getActionSheetState } from '../reducers/actionSheet';
import {
    Toptips,
    Toast,
    Dialog,
    ActionSheet
} from 'react-weui'
import ScanQrcodePayment from '../components/scan-qrcode-payment';
import moment from 'moment';

class App extends Component {

    static timer = null;
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
  
    componentDidMount() {
        // 缓存最后期数
        window.sessionStorage.setItem('lastPeriods', moment().format('YYYYMM'));
    }
    render() {
        
        const {prompt, hideToast, hideToptics, hideDialog, actionSheetState} = this.props;

        if (this.props.prompt.toastIsShow) {
            this.timer = setTimeout( () => {
                hideToast()
            },3000 )
        }
        if (this.props.prompt.topticsIsShow) {
            this.timer = setTimeout( () => {
                hideToptics()
            }, 3000 )
        }
        return (
            <div id="app">
                
                {
                    prompt.toastIsShow &&
                    <Toast icon={prompt.icon} show={true}> {prompt.toastMsg} </Toast>
                }
                {
                    prompt.loadingIsShow &&
                   <Toast icon="loading" show={true}> {prompt.loadMsg} </Toast>
                }
                {
                    prompt.topticsIsShow &&
                    <Toptips type="warn" show={true}> {prompt.topticsMsg} </Toptips>
                }
                {
                    prompt.dialogIsShow && 
                        <Dialog type="ios" title={prompt.dialogTitle} buttons={[
                            {
                                type: 'default',
                                label: '取消',
                                onClick: () => {hideDialog()}
                            },
                            {
                                type: 'primary',
                                label: '确定',
                                onClick: () => {
                                    prompt.dialogAffirm();
                                    hideDialog();
                                }
                            }
                        ]} show={prompt.dialogIsShow}>
                        {prompt.dialogDescription}
                    </Dialog>
                }
                {
                    // 调用actionSheet
                    actionSheetState.callActionSheet &&  <ActionSheet
                    menus={actionSheetState.actionSheetMenus}
                    actions={actionSheetState.actionSheetActions}
                    show={true}
                    type="ios"
                    onRequestClose={this.props.closeActionSheet}
                />
                }
                {
                    // 调用二维码支付
                    actionSheetState.qrcodePaymentShow && <ScanQrcodePayment
                    qrcodeSrc={this.props.actionSheetState.qrcodePaymentSrc}
                    onClick={this.props.closeQrcodePayment}
                />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        prompt: state.promptReducer,
        actionSheetState: getActionSheetState(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(PromptType, dispatch),
        ...bindActionCreators(actionSheetAction, dispatch)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App)