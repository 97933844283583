import {SHOW_PROMPT, HIDE_PROMPT, SHOW_TOAST, SHOW_TOPTICS, HIDE_TOAST, HIDE_TOPTICS, SHOW_LOADING, HIDE_LOADING, SHOW_DIALOG, HIDE_DIALOG} from './constants'

const initialState = {
    isShow: false,
    toastIsShow: false,
    topticsIsShow: false,
    loadingIsShow: false,
    dialogIsShow: false
};

export default (state = initialState, action = {}) =>  {
    switch (action.type) {
        case SHOW_PROMPT:
            return {
                ...state,
                ...initialState,
                promptMsg: action.promptMsg,
                isShow: true
            }
        case SHOW_TOAST:
            return {
                ...state,
                ...initialState,
                toastMsg: action.toastMsg,
                mode: 'toast',
                icon: action.icon === 'success' ? 'success-no-circle' : action.icon === 'warn' ? 'warn' : 'none',
                toastIsShow: true
            }
        case HIDE_TOAST:
            return {
                ...state,
                toastIsShow: false
            }
        case SHOW_TOPTICS:
            return {
                ...state,
                ...initialState,
                topticsMsg: action.topticsMsg,
                topticsIsShow: true
            }
        case HIDE_TOPTICS:
            return {
                ...state,
                topticsIsShow: false
            }
        case HIDE_PROMPT:
            return {
                ...state,
                isShow: false
            }
        case SHOW_LOADING:
            return {
                ...state,
                ...initialState,
                loadingIsShow: true,
                loadMsg: action.loadMsg
            }
        case HIDE_LOADING:
            return {
                ...state,
                ...initialState,
                loadingIsShow: false
            }
        case SHOW_DIALOG:
            return {
                ...state,
                ...initialState,
                dialogIsShow: true,
                dialogTitle: action.title,
                dialogDescription: action.description,
                dialogAffirm: action.affirm
            }
        case HIDE_DIALOG: 
            return {
                dislogIsShow: false
            }
        default:
            return state;
    }
};
