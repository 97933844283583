
/**
 * actions
 * @type {string}
 */
const ADD_WEIXIN_INFO = 'ADD_WEIXIN_INFO';
const ADD_WEIXIN_CODE = 'ADD_WEIXIN_CODE';

// APPID 公众号的唯一标识
export const APPID = 'wx2a9bedd8e67511ed';
// 开放平台
export const OPEN_APPID = 'wx11d4afaec1588e31';
// 授权后重定向的回调链接地址， 请使用 urlEncode 对链接进行处理
export const REDIRECT_URI = 'http://xmadwater.aijs.net/';
export const SCOPE = 'snsapi_userinfo';
export const LOGIN_SCOPE = 'snsapi_login';

/**
 * 微信url模板
 * @type {string}
 */

/**
 * 用户同意授权，获取code 地址
 appid 必须 公众号的唯一标识
 redirect_uri	是	授权后重定向的回调链接地址， 请使用 urlEncode 对链接进行处理
 response_type	是	返回类型，请填写code
 scope	是	应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
 state	否	重定向后会带上state参数，开发者可以填写a-zA-Z0-9的参数值，最多128字节
 #wechat_redirect	是	无论直接打开还是做页面302重定向时候，必须带此参数
 */
export const AUTHORIZE_BASE_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect';
export const WEIXIN_LOGIN_URL = 'https://open.weixin.qq.com/connect/qrconnect?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect';
// 弹出授权页面地址
// const GET_CODE_USERINFO = AUTHORIZE_BASE_URL.replace('APPID', APPID)
//     .replace('REDIRECT_URI', encodeURIComponent(REDIRECT_URI))
//     .replace('SCOPE', 'snsapi_userinfo')
//     .replace('STATE', Math.floor(Math.random() * 10));


export const actionTypes = {
    ADD_WEIXIN_CODE,
    ADD_WEIXIN_INFO,
};

export const SET_LOGINED = 'USER/SET_LOGIN';

