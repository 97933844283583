import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classnames';

import './miv-container.scss';

class MivContainer extends Component {

    render() {
        const {children, blank, className, ...other} = this.props;
        const cls = classNames({
            'miv__container': true,
            'blank': blank,
        }, className);

        return (
            <div className={cls} {...other}>
                { children }
            </div>
        )
    }
}

MivContainer.propTypes = {
    // 如果为真，则背景空白
    blank: PropTypes.bool
};

MivContainer.defaultProps = {
    blank: false
};


export default MivContainer;