import React, {Component} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { actions as PromptType} from '../../common/prompt/actions';
import {actions as authActions, isLogined} from '../../reducers/auth';
import MivContainer from "../../components/miv-container/miv-container";
import {
    Button,
    CellBody,
    Form,
    FormCell,
    Input} from "react-weui";

import { httpPost } from '../../utils/';

import {path, HOST} from '../../constants';

import './login.scss';

class SettingPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            telephone: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.formSubmitEvent = this.formSubmitEvent.bind(this);
        document.title = '设置登陆密码';
    }
    componentWillMount() {
        if (!localStorage.getItem('openid')) {
            this.props.history.replace({
                pathname: path.home
            })
        }
    }
    componentDidMount() {
        let telephone = this.props.location.state;
        this.setState({
            telephone
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value.replace(/\s+/g, "")
        });
    }

    /**
     * 提交
     */
    formSubmitEvent(event) {
        event.preventDefault();
        const { telephone, password } = this.state;
        if (!password || password.length < 6) {
            this.props.showToptics('请正确输入密码(至少6位)');
            return false;
        }
        const id = JSON.parse(sessionStorage.getItem('staff')).id
        
        httpPost(HOST.setPassword, {
            telephone,
            openId: localStorage.getItem('openid'),
            id,
            password
        })
        .then(res => {
            
            this.props.setLoginInfo(localStorage.getItem('openid'), JSON.parse(sessionStorage.getItem('staff')));
            this.props.history.push({
                pathname: path.setPasswordSuccess
            })
        })
        .catch(err => {
            this.props.doShowToptics(err.msg);
        })
    }



    render() {
        return (
            <MivContainer className="author_container">
                <form ref={(ref) => { this.form = ref; }} onSubmit={this.formSubmitEvent} onChange={this.handleInputChange}>
                    <Form className="input_wrap margin-bottom">
                        <FormCell className="bottom_line">
                            <CellBody>
                                <Input type="password" placeholder="请设置密码" name="password" />
                            </CellBody>
                        </FormCell>
                        <div className="padding margin-top">
                            <Button className="bgcolor-level11" onClick={ this.formSubmitEvent }>确定</Button>
                        </div>
                        <div className="text-center color-level3 sm-font">
                            请牢记登录密码，该密码用于隐私操作
                        </div>
                    </Form>
	            </form>
                
            </MivContainer>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authorizeUrl: state.weixinAuthorize.authorizeLink,
        pathname: ownProps.location.pathname,
        prompt: state.promptReducer,
        isLogined: isLogined(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(authActions, dispatch),
        ...bindActionCreators(PromptType, dispatch)
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(SettingPassword);