import storage from './storage';
import globalData from './globalData';
import {httpGet, httpPost, httpPostFile} from './httpRequest'
import {getUrlParam} from './url';
import _ from 'lodash';

// 获取响应数据
const getResponseData = (response) => response.data.data;

// 将异步获取数组转换为 {id:xx, label:xx}形式
const addLabelToArray = function(array) {
    array.forEach( (arr, index) => {
        if (arr.name || arr.booksCode) {
            let label = arr.name || arr.booksCode;
            let obj;
            if (label) {
                obj = Object.assign({}, arr, {label})
            }
    
            array[index] = obj;
        }

    } )

    return array;
}

// 深拷贝
const cloneDeep = obj => _.cloneDeep(obj);

const isObject = (data) => Object.prototype.toString.call(data) === "[object Object]";
const isArray = (data) => Object.prototype.toString.call(data) === "[object Array]";
const formatFloat = (f, digit) => {
    if (digit === void 0) {digit = 1}
    var m = Math.pow(10, digit);
    return Math.round(f * m, 10) / m;
  }

export {
    storage,
    globalData,
    httpGet,
    httpPost,
    httpPostFile,
    getUrlParam,
    addLabelToArray,
    getResponseData,
    cloneDeep,
    isObject,
    isArray,
    formatFloat
}
