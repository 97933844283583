import Loadable from 'react-loadable';
import Loading from './Loading';
export default function ImportComponent(opts) {
    return Loadable(Object.assign({
        loading: Loading,
        delay: 300,
        timeout: 10000
      },opts));
};

export const MapComponent = (opts) => {
    return Loadable.Map(Object.assign({
        loading: Loading,
        delay: 300,
        timeout: 10000
      },opts));
}