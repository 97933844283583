import {loginActions} from '../pages/login';
import {getWeixinAuthorizeUri} from '../common/weixin'

export const STARGAZERS_REQUEST = 'STARGAZERS_REQUEST'
export const STARGAZERS_SUCCESS = 'STARGAZERS_SUCCESS'
export const STARGAZERS_FAILURE = 'STARGAZERS_FAILURE'
export const STARRED_REQUEST = 'STARRED_REQUEST'
export const STARRED_SUCCESS = 'STARRED_SUCCESS'
export const STARRED_FAILURE = 'STARRED_FAILURE'
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'

export {
    getWeixinAuthorizeUri,
    loginActions
}