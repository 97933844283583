// const SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:9011' : 'http://47.104.77.135:9011';
const SERVER = 'http://47.104.77.135:9011';

/** 
 * 请求url地址
 **/
const HOST = {
    // authorizeLink,
    wx_getConfig: SERVER + '/weixin-service/api/getWXConfig',
    // 公众号缴费 参数: billIds
    wx_request_create_order: SERVER + '/weixin-service/public/unifiedOrder',
    // 用户列表 管理员代缴
    wx_request_create_order_userList_admin: SERVER + '/weixin-service/public/unifiedOrder',
    // 管理员代缴回调判断是否缴费成功
    wx_admin_pay_callback: SERVER + '/master-service/bill/queryBillIsPay?billIds=',
    // 扫码支付
    wx_scan_pay: SERVER + '/weixin-service/sweepYards/unifiedOrder',
    // 现金缴费
    // 缴费方式标识 在 src/constants/index.js 中的 paymentMethod 常量
    /*
    用户详情现金缴费
     * @param source 请求来源 1、小程序 2管理后台
     * @param paidMethod 支付方式 1：微信支付或扫码支付 2：现金缴费 3：管理员代缴
     *  @param billIds 账单id
     */
    cash_payment: SERVER + '/master-service/bill/payBill',
    /**
     * 用户列表现金缴费
     */
    listPayment: SERVER + '/master-service/bill/listPayment',
    wx_webAuth: SERVER + '/weixin-service/api/webAuth?code=',
    // 开放平台第三方登录
    open_webAuth: SERVER + '/weixin-service/api/open/webAuth?code=',
    operate_getHistory: SERVER + '/master-service/OperateHistory/getHistory',
    // 获取master列表
    master_list: SERVER + '/master-service/master/list',
    master_findByCode: SERVER + '/master-service/master/findMasterDetByCode',
    // 根据期数获取账单
    get_bill_by_periods: SERVER + '/master-service/bill/getBillByPeriods',
    master_create: SERVER + '/master-service/master/createMaster',
    master_update: SERVER + '/master-service/master/goUpdate',
    master_bundle: SERVER + '/master-service/master/bundle',

    // 解绑水表 ?meterId=
    unbindingMeter: SERVER + '/master-service/master/unbindingMeter',
    // 导出用户模板
    master_export: SERVER + '/master-service/master/export',

    staff_save: SERVER + '/settings-service/staff/saveStaff', 
    staff_removeByIdEquals: SERVER + '/settings-service/staff/remove?id=',
    staff_findAdmin: SERVER + '/settings-service/staff/findAdmin',
    // 登录验证
    staff_checkPhone: SERVER + '/settings-service/staff/checkPhone',
    // 获取抄表员列表
    staff_getReadMeterStaff: SERVER + '/settings-service/staff/getReadMeterStaff',
    // 新增修改抄表员
    staff_saveAndModify: SERVER + '/settings-service/staff/saveStaff',
    // 校验openid
    checkWithOpenid: (openid) => SERVER + '/settings-service/staff/staffLogin?openid=' + openid,
    // 参数 openid
    staff_login: SERVER + '/settings-service/staff/staffLogin',
    // 获取登录验证码
    getLoginCode: SERVER + '/message-service/message/getLoginCode',
    // 设置密码
    setPassword: SERVER + '/settings-service/staff/setPassword',
   
    // 获取水表信息 - 修改水表
    meter_getMeterById: SERVER + '/master-service/meter/getMeterById?id=',
    // 换表 master-service/meter/change  参数id stopValue installDate installValue
    meter_change: SERVER + '/master-service/meter/change',
    // 修改水表 master-service/meter/modifyMeter 参数id
    meter_modify: SERVER + '/master-service/meter/modifyMeter',

    // 创建水表
    meter_create: SERVER + '/master-service/meter/createMeter',
    /***
     * 分页搜索
     *
     * @param response  response
     * @param stateCode 状态码
     * @param address   地址
     * @param booksCode 统测号
     * @param page      页码
     * @param size      每页显示的数量
     * @param search    搜索关键词
     * @throws IOException
     */
    meter_list: SERVER + '/master-service/meter/list',
    meter_record: SERVER + '/master-service/meter/getChangeRecord',
    meter_getMeterByMasterNum: SERVER + '/master-service/meter/getMeterByMasterNum',
    // 保存抄表记录
    readMeter_addReadMeter: SERVER + '/master-service/readMeter/addReadMeter',
    readerMeter_bundle: SERVER + '/master-service/readMeter/bundle',
    // 抄表导出模板
    // /readMeter/export?booksCode=AB000001&periods=2018-09
    readMeter_export: SERVER + '/master-service/readMeter/export',
    // 用户表修改 人口数、本期抄表数、浮动金额
    modifyMeterBill: SERVER + '/master-service/master/modifyMeterBill',
    // 人口数、本期抄表数、浮动金额 = 计算结果
    calculate: SERVER + '/master-service/master/calculate',

    // 读取期数
    readMeter_periodsByBooksCode: SERVER + '/master-service/readMeter/periods',

    // 根据统测号和期数获取抄表记录
    readMeter_findReadMeterRecordByPeriodsAndBooksCode: SERVER + '/master-service/readMeter/findReadMeterRecordByPeriodsAndBooksCode',
    readMeter_release: SERVER + '/master-service/bill/buildBill',
    // 抄表记录列表
    highFloatingList: SERVER + '/master-service/readMeter/highFloatingList',
    books_create: SERVER + '/settings-service/books/create',
    books: SERVER + '/master-service/books',
    books_getBooks: SERVER + '/settings-service/books/getBooks',
    // 查找所有统测号
    books_findAll: SERVER + '/settings-service/books/findAll',
    /**
    * @param company   水司
    * @param district  地区
    * @param business  营业厅id
    * @param page      页码
    * @param size      每页数量
    * @param search    搜索关键词
    */
    books_search: SERVER + '/settings-service/books/search',

    // 修改统册号：books-service/books/modifyBooks
    books_modify: SERVER + '/settings-service/books/modifyBooks',

    // 校验能否删除统测号
    validBooksCode: SERVER + '/settings-service/books/validBooksCode',
    // 删除统测号 delete
    books_removeByBooksCode: SERVER + '/settings-service/books/removeByBooksCode',

    // 获取所有水司
    company_findAll: SERVER + '/settings-service/company/findAll',
    // 创建水司   参数：name 修改 带参数(id)
    company_saveOrUpdate: SERVER + '/settings-service/company/saveOrUpdate',
    // 获取单个水司  参数id
    company_getOne: SERVER + '/settings-service/company/getCompany',
    // 删除水司 参数id
    company_remove: SERVER + '/settings-service/company/del',

    // 获取所有区域
    district_findAll: SERVER + '/settings-service/district/findAll',
    // 创建区域  参数：name 修改 带参数(id)
    district_saveOrUpdate: SERVER + '/settings-service/district/saveOrUpdate',
    // 获取单个区域  参数id
    district_getOne: '/settings-service/district/getDistrict',
    // 删除区域 参数id
    district_remove: SERVER + '/settings-service/district/del',

    // 获取所有抄表范围
    zone_findAll: SERVER + '/settings-service/zone/findAll',
    // 创建抄表范围   参数：name 修改 带参数(id)
    zone_saveOrUpdate: SERVER + '/settings-service/zone/saveOrUpdate',
    // 获取单个抄表范围  参数id
    zone_getOne: SERVER + '/settings-service/zone/getZone',
    // 删除抄表范围 参数id
    zone_remove: SERVER + '/settings-service/zone/del',

    // 获取所有抄表周期
    readCycle_findAll: SERVER + '/settings-service/readCycle/findAll',
    // 创建抄表周期   参数：name 修改 带参数(id)  注意name的值只能为：数字不能有中文 
    readCycle_saveOrUpdate: SERVER + '/settings-service/readCycle/saveOrUpdate',
    // 获取单个抄表周期  参数id
    readCycle_getOne: SERVER + '/settings-service/readCycle/getReadCycle',
    // 删除抄表周期 参数id
    readCycle_remove: SERVER + '/settings-service/readCycle/del',

    business_findAll: SERVER + '/settings-service/business/findBusiness',
    business_saveOrModify: SERVER + '/settings-service/business/saveOrModify',
    business_removeByIdEquals: SERVER + '/settings-service/business/remove',

    // 获取角色列表
    role_finAll: SERVER + '/settings-service/role/findAll',
    role_saveOrUpdate: SERVER + '/settings-service/roleAuthorization/saveOrUpdate',
    role_remove: SERVER + '/settings-service/roleAuthorization/removeRoleAuthorization',

    // 权限列表  192.168.1.56:9005/authorization/findAll
    authorize_findAll: SERVER + '/settings-service/authorization/findAll',
    
    // 保存修改微信设置
    wx_saveOrupdate: SERVER + '/settings-service/wxConfig/saveOrupdate',
    // 获取微信接口设置
    wx_getWxConfig: SERVER + '/settings-service/wxConfig/getWxConfig',

    // 显示所有打印机
    printer_showAllPrinter: SERVER + '/print-service/printer/showAllPrinter',
    printer_printerList: SERVER + '/print-service/printer/list',
    /**
     * 获取打印记录
     * @param date 打印时间 年月(如: 201801)
     * @param businessName 营业厅名称
     * @param ticketType 发票类型 1、催缴单  2.普通发票
     * @param page
     * @param size
     */
    print_records: SERVER + '/print-service/invoice/record',
    // invoice/detail?id=xxxx
    print_record_detail: SERVER + '/print-service/invoice/detail',
    /**
     *
     * 打印发票/催缴单
     * @param masterIds 用户id集合
     * @param printerId 打印机id
     * 如: invoice/printInvoices?masterIds=xxxxxx&printerId=1
     */
    print_handle: SERVER + '/print-service/invoice/printInvoices',
    bill_report: SERVER + '/master-service/bill/report',
    getPeriodList: SERVER + '/master-service/bill/getPeriodList',
    // 数据报表
    bill_datatable: SERVER + '/master-service/bill/getDataTable',
    bill_record: SERVER + '/master-service/bill/BillRecord',
    // 下载报表（本月收款统计）
    download_report: SERVER + '/master-service/bill/download_report',
    // 下载报表（本月待缴统计）
    download_uncollected: SERVER + '/master-service/bill/download_uncollected',
    /**
     * 获取收款日期
     */
    getPaidDate: SERVER + '/master-service/bill/getPaidDate',
    /**
     * 获取支付详情
     * 请求参数 ticketNum
     */
    getPaymentDet: SERVER + '/master-service/bill/getPaymentDet',
    bill_recordDetail: SERVER + '/master-service/bill/BillRecordDetail',
    bill_countBill: SERVER + '/master-service/bill/countBill?countType=',

    /**
     * get
     * 发送通知前 验证手机号
     * message-service/message/getAuthCode?phone=
     */
    sendAuthCode: SERVER + '/message-service/message/getAuthCode',
    /**
     * 水费温馨提醒
     * @param masterNums 用户号
     */
    // sendNotice: SERVER + '/message-service/message/sendRemindSms',
    sendNotice: SERVER + '/master-service/master/sendRemindMessage',
    /**
     * 保存invoice配置
     */
    invoiceConfig_saveOrUpdate: SERVER + '/print-service/invoiceConfig/saveOrUpdate',
    /**
     * 获取invoice配置
     */
    getInvoiceConfig: SERVER + '/print-service/invoiceConfig/getInvoiceConfig',

    /** 设置统一减免吨数 post ?id=xxx&reductionvalue=xxxx */
    setReduction: SERVER + '/settings-service/reduction/modifyReduction',
    /** 获取统一减免吨数 get */
    getReduction: SERVER + '/settings-service/reduction/getReduction',
    /** 申请退款 
     * @param billId   账单id
     * @param reason   退款原因
     * @param openid   退款申请人
    */
    requestRefund: SERVER + '/weixin-service/refund',
    /**
     * 为统测号最后一期生成抄表列表
     */
    readmeterBuild: SERVER + '/master-service/readMeter/readmeterBuild'
}
export default HOST;