
const initialState = {
    callActionSheet: false,
    actionSheetMenus: [],
    actionSheetActions: [],
    qrcodePaymentShow: false,
    qrcodePaymentSrc: ''
}

// action types
export const types = {
    /** ActionSheet */
    CALL_ACTIONSHEET: "ACTION_SHEET/CALL_ACTIONSHEET", 
    CLOSE_ACTIONSHEET: "ACTION_SHEET/CLOSE_ACTIONSHEET",
    CALL_QRCODEPAYMENT: "ACTION_SHEET/CALL_QRCODEPAYMENT",
    CLOSE_QRCODEPAYMENT: "ACTION_SHEET/CLOSE_QRCODEPAYMENT"
};

export const actions = {
    /** 调用 actionSheet */
    callActionSheet: (actionSheetMenus, actionSheetActions) => ({
        type: types.CALL_ACTIONSHEET,
        actionSheetMenus,
        actionSheetActions
    }),
    /** 关闭 actionSheet */
    closeActionSheet: () => ({
        type: types.CLOSE_ACTIONSHEET
    }),
    /** 调用 二维码支付 */
    callQrcodePayment: (qrcodePaymentSrc) => ({
        type: types.CALL_QRCODEPAYMENT,
        qrcodePaymentSrc
    }),
    /** 关闭 qrcodePayment */
    closeQrcodePayment: () => ({
        type: types.CLOSE_QRCODEPAYMENT
    }),
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case types.CALL_ACTIONSHEET:
            return {...state,
                callActionSheet: true, 
                actionSheetMenus: action.actionSheetMenus,
                actionSheetActions: action.actionSheetActions
            }
        case types.CLOSE_ACTIONSHEET:
        case types.CLOSE_QRCODEPAYMENT:
            return {...state, ...initialState }
        case types.CALL_QRCODEPAYMENT:
            return {
                ...state,
                ...initialState,
                qrcodePaymentShow: true,
                qrcodePaymentSrc: action.qrcodePaymentSrc
            }
        default:
            return state;
    }
}

export default reducer;

// 是否调用 actionSheet
export const getActionSheetState = state => state.actionSheetReducer;