import React, {Component} from 'react';
import { connect } from 'react-redux';

import {
    Msg
} from 'react-weui';

import '../result-feedback/result-feedback.scss';
import { path } from '../../constants';

class SetPasswordSuccess extends Component {

    constructor(props) {
        super(props);
        let title = '密码设置成功';
        let msgTitle = '添加成功';
        let type = 'success';
        let description = '密码设置成功'
        let buttons = [
            { type: 'primary', label: '前往管理',  onClick: () => {
                this.props.history.replace({
                    pathname: path.home
                })
                // window.location.href = path.home + '?timestamp='+((new Date()).getTime()+Math.random());
            }}
        ]
        this.state = {
            title,
            type,
            msgTitle,
            description,
            buttons
        };
    }
    componentWillMount() {
        if (!localStorage.getItem('openid')) {
            this.props.history.replace({
                pathname: path.home
            })
        }
    }
    componentDidMount() {
        document.title = this.state.title;
    }

    render() {
        const { msgTitle, description, type, buttons } = this.state;
        return (
            <div className="payment__result__page">

                <Msg
                    type={ type }
                    title={ msgTitle }
                    description={ description }
                    buttons={ buttons }
                />

            </div>
        )
    }
}

export default connect()(SetPasswordSuccess);