import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './scan-qrcode-payment.scss'
class ScanQrcodePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
         };
    }
    componentDidMount() {
        const {qrcodeSrc} = this.props;
        let qrcodeEl = this.refs.myQrcode;
        let qrcode = new window.QRCode(qrcodeEl);
        qrcode.makeCode(qrcodeSrc);
    }
    render() {
        const {onClick} = this.props;
        return (
            <div id="qrcode" 
                className="qrcode" 
                onClick={onClick}>
                <div className="qrcode_wrapper">
                    <h5 className="qrcode_title">{this.props.title}</h5>
                    <div id="qrcode_img" className="qrcode_img" ref="myQrcode" >

                    </div>
                    <p className="qrcode_description_wrap">
                        <span className="qrcode_description_text">{this.props.description || '请使用微信扫描二维码进行账单支付'}</span>
                    </p>
                </div>

            </div>
        );
    }
}
ScanQrcodePayment.propsType = {
    show: PropTypes.bool.isRequired,
    qrcodeSrc: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
}
ScanQrcodePayment.defaultProps = {
    show: false,
    title: '扫码支付'
}

export default ScanQrcodePayment;