import { combineReducers } from 'redux';
import auth from './auth';
import login from '../pages/login/reducer'
import {weixinAuthorize, weixinLoginLink, weixinInfo} from '../common/weixin';
import { promptReducer } from '../common/prompt';
import actionSheetReducer from './actionSheet';
import merge from 'lodash/merge';
import {
    RESET_ERROR_MESSAGE
} from '../actions'

// Updates an entity cache in response to any action with response.entities.
const entities = (state = { users: {}, repos: {} }, action) => {
    if (action.response && action.response.entities) {
      return merge({}, state, action.response.entites)
    }
    return state
  }
  
  // Updates error message to notify about the failed fetches.
  const errorMessage = (state = null, action) => {
    const { type, error } = action
  
    if (type === RESET_ERROR_MESSAGE) {
      return null
    } else if (error) {
      return error
    }
  
    return state
  }


export default combineReducers({
  auth,
  entities,
  errorMessage,
  login,
  weixinAuthorize,
  weixinLoginLink,
  weixinInfo,
  promptReducer,
  actionSheetReducer
})

