import {
    LOGIN_INPUT_TELEPHONE,
    LOGIN_SUBMIT,
    LOGIN_INPUT_CODE
} from './actionTypes'

const initialState = {
    telephone: '',
    vcode: ''
}

const login = (state = initialState, action) =>{
    switch(action.type) {
        case LOGIN_INPUT_TELEPHONE:
            return {
                ...state,
                telephone: action.telephone
            }
        case LOGIN_INPUT_CODE:
        return {
            ...state,
            vcode: action.vcode
        }
        case LOGIN_SUBMIT:
        default:
            return state;
    }
}

export default login;