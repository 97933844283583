import React from 'react'
import {Route} from 'react-router-dom';
import {Provider} from "react-redux";
import App from './pages/App';
import ImportComponent from './components/ImportComponent';

import {path} from './constants';

/**
 * print
 */
// import {
//     // PrintManage,
//     PrintRecord,
//     ReminderManage,
//     // InvoiceManage,
//     EditPrint
// } from './pages/print'
const AsyncPrintRecord = ImportComponent({ loader: () => import('./pages/print/print-record') });
const AsyncReminderManage = ImportComponent({ loader: () => import('./pages/print/reminder-manage') });
const AsyncEditPrint = ImportComponent({ loader: () => import('./pages/print/edit-print') });

/**
 * 错误页面
 */
// import {Page404} from './pages/error';
const AsyncPage404 = ImportComponent({ loader: () => import('./pages/error') });

// import DevTools from './config/DevTools'
// import PrinterManage from './pages/print/printer-manage';
const AsyncPrinterManage = ImportComponent({ loader: () => import('./pages/print/printer-manage') });

// import ManageListPage from './pages/manage-list-page';
const AsyncManageListPage = ImportComponent({ loader: () => import('./pages/manage-list-page') });

// import AddAdmin from './pages/setting/add-admin';
const AsyncAddAdmin = ImportComponent({ loader: () => import('./pages/setting/add-admin') });

// import AddRole from './pages/setting/add-role';
// import ResultFeedback from './pages/result-feedback/result-feedback';
const AsyncResultFeedback = ImportComponent({ loader: () => import('./pages/result-feedback/result-feedback') });

// import TemplateManageList from './pages/setting/template-manage-list';
const AsyncTemplateManageList = ImportComponent({ loader: () => import('./pages/setting/template-manage-list') });

// import templateFormEdit from './pages/setting/template-form-edit';
const AsyncTemplateFormEdit = ImportComponent({ loader: () => import('./pages/setting/template-form-edit') });

// import Scan from './pages/scan';
const AsyncScan= ImportComponent({ loader: () => import('./pages/scan') });

// import Authorize from './pages/authorize';
// import ReminderTemplate from './pages/print/reminder-template';
const AsyncReminderTemplate  = ImportComponent({ loader: () => import('./pages/print/reminder-template') });

// const supportsHistory = 'pushState' in window.history;
const AsyncDashBoard = ImportComponent({ loader: () => import('./pages/dashboard') })
const AsyncPaymentDetail = ImportComponent({ loader: () => import('./pages/user/payment-detail') })
const AsyncPaymentConfirm = ImportComponent({ loader: () => import('./pages/user/payment-confirm') })
const AsyncPaymentRecordList = ImportComponent({ loader: () => import('./pages/user/payment-record-list') })

const AsyncUserList = ImportComponent({ loader: () => import('./pages/user/user-list') })
const AsyncUserDetail = ImportComponent({ loader: () => import('./pages/user/user-detail') })
const AsyncAddUser = ImportComponent({ loader: () => import('./pages/user/add-user') })
const AsyncEditUser = ImportComponent({ loader: () => import('./pages/user/edit-user') })
const AsyncUserBundleImport = ImportComponent({ loader: () => import('./pages/user/user-bundle-import') })
const AsyncAddUserLogger = ImportComponent({ loader: () => import('./pages/user/add-user-logger') })

const AsyncReport = ImportComponent({ loader: () => import('./pages/report/report') })

const AsyncMeterList = ImportComponent({ loader: () => import('./pages/meter/meter-list') })
const AsyncMeterManageList = ImportComponent({ loader: () => import('./pages/meter/meter-manage-list') })
const AsyncEditMeter = ImportComponent({ loader: () => import('./pages/meter/edit-meter') })
const AsyncAddMeter = ImportComponent({ loader: () => import('./pages/meter/add-meter') })
const AsyncChangeRecord = ImportComponent({ loader: () => import('./pages/meter/change-record') })
const AsyncMeterReadList = ImportComponent({ loader: () => import('./pages/meter/meter-read-list') })
const AsyncMeterReadRecord = ImportComponent({ loader: () => import('./pages/meter/meter-read-record') })
const AsyncSelectReadPeriod = ImportComponent({ loader: () => import('./pages/meter/select-read-period') })
const AsyncBundleImportReadMeter = ImportComponent({ loader: () => import('./pages/meter/bundle-import-read-meter') })


const AsyncReadMeterStaffList = ImportComponent({ loader: () => import('./pages/staff/read-meter-staff-list') })
const AsyncAddReadMeter = ImportComponent({ loader: () => import('./pages/staff/add-read-meter') })
const AsyncEditReadMeter = ImportComponent({ loader: () => import('./pages/staff/edit-read-meter') })

const AsyncBooksList = ImportComponent({ loader: () => import('./pages/books/books-list') })
const AsyncSelectBooks = ImportComponent({ loader: () => import('./pages/books/select-books') })
const AsyncEditBooks = ImportComponent({ loader: () => import('./pages/books/edit-books') })

const AsyncWeixinPushManageList = ImportComponent({ loader: () => import('./pages/setting/weixin-push-manage-list') })
const AsyncSMSManageList = ImportComponent({ loader: () => import('./pages/setting/sms-manage-list') })
const AsyncBusinessManageList = ImportComponent({ loader: () => import('./pages/setting/business-manage-list') })
const AsyncRoleManageList = ImportComponent({ loader: () => import('./pages/setting/role-manage-list') })
const AsyncAdminManageList = ImportComponent({ loader: () => import('./pages/setting/admin-manage-list') })
const AsyncAdminEditor = ImportComponent({ loader: () => import('./pages/setting/admin-editor') })
const AsyncRoleEditor = ImportComponent({ loader: () => import('./pages/setting/role-editor') })
const AsyncWeixinSetting = ImportComponent({ loader: () => import('./pages/setting/weixin-setting') })
const AsyncAccountSettings = ImportComponent({ loader: () => import('./pages/setting/account-setting') })
const AsyncSendNotice = ImportComponent({ loader: () => import('./pages/setting/send-notice') })
const AsyncSetReduction = ImportComponent({ loader: () => import('./pages/setting/set-reduction') })

const AsyncHome = ImportComponent({ loader: () => import('./pages/home') })
const AsyncLogin = ImportComponent({ loader: () => import('./pages/login/login') })
const AsyncSettingPassword = ImportComponent({ loader: () => import('./pages/login/setting-password') })
const AsyncSePasswordSuccess = ImportComponent({ loader: () => import('./pages/login/setPassword-success') })

const AsyncOrderDetail = ImportComponent({ loader: () => import('./pages/user/order-detail') })
// Datatable 数据报表
const AsyncDatatable = ImportComponent({ loader: () => import('./pages/report/datatable') })
const Rout = ({store}) => (
    <Provider store={store}>
        <div className="app-container">
            <Route component={App}/>
            {/* 用户登录 */}
            <Route exact path={path.home} component={AsyncHome}/>
            <Route path={path.login} component={AsyncLogin} />
            <Route path={path.setPassword} component={AsyncSettingPassword} />
            <Route path={path.setPasswordSuccess} component={AsyncSePasswordSuccess} />

            <Route path={path.dashboard} title="仪表盘" component={AsyncDashBoard} />

            {/*用户*/}
            <Route path={path.paymentDetail} component={AsyncPaymentDetail} />
            <Route path={path.paymentConfirm} component={AsyncPaymentConfirm} />
            <Route path={path.paymentRecordList} component={AsyncPaymentRecordList} />
            <Route path={path.userList} component={AsyncUserList} />
            <Route path={path.userDetail + '/:masterNum'} component={AsyncUserDetail} />
            <Route path={path.addUser} component={AsyncAddUser} />
            <Route path={path.editUser + '/:masterNum'} component={AsyncEditUser} />
            <Route path={path.userBundleImport} component={AsyncUserBundleImport} />
            <Route path={path.addUserLogger} component={AsyncAddUserLogger} />

            {/* 报表 */}
            <Route path={path.report} component={AsyncReport} />
            <Route path={path.datatable} component={AsyncDatatable} />

            {/*表务*/}
            <Route path={path.meterList} component={AsyncMeterList} />
            <Route path={path.meterManageList} component={AsyncMeterManageList} />
            <Route path={path.editMeter + '/:meterId'} component={AsyncEditMeter} />
            <Route path={path.addMeter} component={AsyncAddMeter} />
            <Route path={path.changeRecord} component={AsyncChangeRecord} /> 
            <Route path={path.meterReadList + '/:booksCode/:periods'} component={AsyncMeterReadList} />
            <Route path={path.meterReadRecord + '/:booksCode/:periods'} component={AsyncMeterReadRecord} />
            <Route path={path.selectReadPeriod + '/:booksCode'} component={AsyncSelectReadPeriod} />
            <Route path={path.bundleImportReadMeter + '/:periods'} component={AsyncBundleImportReadMeter} />

            {/*抄表人员*/}
            <Route path={path.readMeterStaffList} component={AsyncReadMeterStaffList} />
            <Route path={path.addReadMeter} component={AsyncAddReadMeter} />
            <Route path={path.editReadMeter} component={AsyncEditReadMeter} />

            {/*统测号*/}
            <Route path={path.booksList} component={AsyncBooksList} />
            <Route path={path.selectBooks} component={AsyncSelectBooks} />
            <Route path={path.editBooks} component={AsyncEditBooks} />
            {/* <Route path={path.addBooks} component={AddBooks} /> */}

            {/* 扫码收款 */}
            <Route path={path.scan} component={AsyncScan} />

            {/*管理与设置*/}
            {/* <Route path={path.settingManageList} component={SettingManageList} /> */}
            <Route path={path.sMSManageList} component={AsyncSMSManageList} />
            <Route path={path.weixinPushManageList} component={AsyncWeixinPushManageList} />
            {/* <Route path={path.noticeManageList} component={NoticeManageList} /> */}
            <Route path={path.businessManageList} component={AsyncBusinessManageList} />
            <Route path={path.roleManageList} component={AsyncRoleManageList} />
            <Route path={path.adminManageList} component={AsyncAdminManageList} />
            {/* <Route exact path={path.businessEditor} component={BusinessEditor} /> */}
            {/* <Route path={path.businessEditor + '/:businessId'} component={BusinessEditor} /> */}
            <Route path={path.addAdmin} component={AsyncAddAdmin} />
            <Route path={path.adminEditor} component={AsyncAdminEditor} />
            <Route path={path.addRole} component={AsyncRoleEditor} />
            <Route path={path.roleEditor} component={AsyncRoleEditor} />
            <Route path={path.weixinSetting} component={AsyncWeixinSetting} />
            <Route path={path.accountSettings} component={AsyncAccountSettings} />
            <Route path={path.setReduction} component={AsyncSetReduction} />

            {/* 管理列表 模板 company district zone readCycle */}
            <Route path={path.manageList + '/:flag'} component={AsyncTemplateManageList} />
            {/* 编辑表单 统一模板 company district zone readCycle */}
            <Route path={path.editPage + '/:flag'} component={AsyncTemplateFormEdit} />

            {/* 打印 */}
            <Route path={path.printRecord + '/:ticketTypeId'} component={AsyncPrintRecord} />
            <Route path={path.editPrint + '/:type'} component={AsyncEditPrint} />
            {/* <Route path={path.invoiceManageList} component={InvoiceManage} /> */}
            {/* <Route path={path.printManageList} component={PrintManage} /> */}
            <Route path={path.printerManage} component={AsyncPrinterManage} />
            <Route path={path.reminderManageList} component={AsyncReminderManage} />
            <Route path={path.reminderTemplate} component={AsyncReminderTemplate} />

            <Route path={path.resultFeedback} component={AsyncResultFeedback} />

            {/* 发送通知 sendNotice */}
            <Route path={path.sendNotice} component={AsyncSendNotice} />
            {/* 订单详情 */}
            <Route path={path.orderDetail} component={AsyncOrderDetail} />

            {/*
                管理菜单列表页
                和 constants 中的 manageListPage 对应 
                如：/menus/sms
            */}
            <Route path={path.menus + '/:page'} component={AsyncManageListPage} />
            {/*错误页面*/}
            <Route path={path.error404} component={AsyncPage404}/>
        </div>
    </Provider>

);

export default Rout;